
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPropertyHistory } from "@/models/PropertyHistory";
import DateUtils from "@/services/utils/DateUtils";
import { HousfyAlertMessage } from "housfy-ui-lib";

@Component({
  name: "PropertyHistory",
  components: {
    HousfyAlertMessage,
  },
})
export default class PropertyHistory extends Vue {
  @Prop({ type: Array, default: () => [] })
  history!: IPropertyHistory[];

  get orderedHistory(): IPropertyHistory[] {
    return this.history.sort((a, b) => {
      return (
        new Date(b.rentedStartedDate).getTime() -
        new Date(a.rentedStartedDate).getTime()
      );
    });
  }

  get orderedHistoryWithReviewedSignatureDate(): IPropertyHistory[] {
    const historyWithCorrectDates = this.orderedHistory.map((item) => {
      const validityDate = item.rentedStartedDate
        ? new Date(item.rentedStartedDate).getTime()
        : undefined;

      const signatureDate = item.contractSignatureDate
        ? new Date(item.contractSignatureDate).getTime()
        : undefined;
      if (signatureDate && validityDate && validityDate < signatureDate)
        item.contractSignatureDate = null;

      return item;
    });
    return historyWithCorrectDates;
  }

  formatDate(stringDate: string) {
    const { day, month, year } = DateUtils.formatDate(stringDate);
    return day + " " + this.$t("common." + month + "Short") + " " + year;
  }
}
